import React from 'react';
import styled from '@emotion/styled';
import { PrimaryBtnLink } from '../components/common';
import bg404 from '../images/404.svg';
import glass from '../images/glass.png';
import { MOBILE } from '../constants/responsive';

const PageContainer = styled('div')`
    display:grid;
    place-content: center;
    margin: 0px auto;
    min-height: 100vh; 
    width:100%;
    background:var(--bg-color-blue) url(${bg404}) 50% no-repeat;
    @media (max-width:${MOBILE}) {
      background:var(--bg-color-blue) url(${bg404}) 50%/ contain no-repeat;
        }

    
 `;
const ContentContainer = styled('div')`
    
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content: center;
    text-align:center;
    row-gap: 1.6rem;
    @media (max-width:${MOBILE}) {
        
        row-gap: 0.8rem;
        }
`;
const Img = styled('img')`
    margin-top: 4.8rem;
    margin-bottom: 2.4rem;
    max-width: 100%;
`;
function NotFound() {
  return (
    <PageContainer>
      <ContentContainer>
        <h2>
          .404 page not found.
        </h2>
        <h3>It looks like an error occurred or the page doesn&prime;t exist.</h3>
        <Img src={glass} alt="glass" />
        <PrimaryBtnLink btnLabel="Back to main page" href="/" inner />
      </ContentContainer>
    </PageContainer>
  );
}

export default NotFound;

export const Head = () => (
  <>
    <title>Digital Marketing Agency Targetiva | 404</title>
    <meta name="description" content="Page not found" />
  </>
);

